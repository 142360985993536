import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import styled from 'styled-components';
import ArrowImg from '../../assets/images/global/collapse_arrow.png';

// import { typeValidation, getChildrenByType } from '../../utils/common';

export const CollapseContext = createContext({});

const CollapseStyled = styled.div`
  .panel {
    p {
      display: inline-block;
    }

    &.active {
      & > .panel__header {
        &::after {
          content: '';
          width: 12px;
          height: 6px;
          background-position: ${(props) =>
            props.theme.name === 'women' ? '1px 0px' : ' -21px 0'};
          right: 15px;
        }
      }

      & > .panel {
        &__content {
          height: 100%;

          & > div {
            border-right: 0;
          }
        }
      }
    }

    &__header {
      cursor: pointer;
      position: relative;
      color: ${(props) => props.theme.prodcut.nameColor};
      font-weight: bold;
      font-size: 14px;
      padding: 10px 20px;
      border-top: ${(props) => props.theme.prodcut.border};
      border-bottom: ${(props) => props.theme.prodcut.border};
      background: ${(props) => props.theme.prodcut.panelHeaderColor};

      &::after {
        content: '';
        width: 6px;
        height: 12px;
        background: url(${ArrowImg}) no-repeat;
        background-position: ${(props) =>
          props.theme.name === 'women' ? '-3px -17px' : ' -23px -17px'};
        transform: translateY(-50%);
        position: absolute;
        top: 50%;
        right: 18px;
        color: ${(props) => props.theme.prodcut.arrowColor};
      }
    }

    &__content {
      font-size: 12px;
      color: ${(props) => props.theme.prodcut.panelContentColor};
      background: ${(props) => props.theme.prodcut.panelContentBg};
    }
  }
`;

const Collapse = ({ className, children, activePanel }) => {
  const [active, setActivePanel] = useState(activePanel);

  function onPanelClick(panelKey) {
    if (active.includes(panelKey)) {
      const newActivePanel = _.remove(active, function (n) {
        return n !== panelKey;
      });
      setActivePanel(newActivePanel);
    } else {
      setActivePanel([...active, panelKey]);
    }
  }

  return (
    <CollapseContext.Provider
      value={{ activePanel: active, setActivePanel: onPanelClick }}
    >
      <CollapseStyled className={className}>{children}</CollapseStyled>
    </CollapseContext.Provider>
  );
};

Collapse.propTypes = {
  children: PropTypes.node,
  // __TYPE: typeValidation('Panel'),
};

Collapse.defaultProps = {
  // __TYPE: 'Collapse',
  current: 0,
  activePanel: [],
};

export default Collapse;

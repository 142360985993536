import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const Container = styled.div`
  padding: 10px 0;
`;

const Item = styled.div`
  font-size: 10px;
  ${(props) => (props.theme.name === 'men' ? 'color: #ffffff;' : '')}
  &:not(:last-child) {
    margin-right: 5px;

    &::after {
      content: '>';
      padding-left: 5px;

      ${(props) => (props.theme.name === 'men' ? 'color: #ff0000;' : '')}
    }
  }
`;

const Breadcrumb = ({ customList, defaultList }) => {
  const { t } = useTranslation();

  let list = [];

  try {
    if (
      defaultList.breadcrumbName.length > 0 &&
      defaultList.breadcrumbName !== undefined
    ) {
      list = defaultList.breadcrumbName;
    } else if (customList.length > 0) {
      list = customList;
    }
  } catch (error) {
    // console.log(error);
    if (customList.length > 0) {
      list = customList;
    }
  }

  // console.log(customList, defaultList.breadcrumbName, list);

  return (
    <Container className="row no-gutters">
      <Item>{t('home_page')}</Item>
      {list.map((item, index) => {
        return <Item key={index}>{t(item)}</Item>;
      })}
    </Container>
  );
};

export default Breadcrumb;

Breadcrumb.defaultProps = {
  customList: [],
  defaultList: [],
};

import React, { useContext } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';

import { StyledNavigation, Item, StyledLink } from './StyledSubList';
import { onCollapseClick } from '../../state/reducers/products';
import LayoutContext from '../../context/LayoutContext';
import SubList from './SubList';
import StaticSubList from './StaticSubList';
import { trackingHelper } from '../../utils/tracking-helper';
import { trackingLinkHelper } from '../../utils/tracking-link-helper';

const Navigation = () => {
  const layoutProps = useContext(LayoutContext);
  const { t,i18n } = useTranslation();
  const { locale } = useSelector((state) => state.common);
  const dispatch = useDispatch();
  const { gender } = layoutProps.pageContext;
  const { typeMenu, otherMenu, allProduct } = useNavigationData();

  const genderTypeMenu = typeMenu.filter((type) => {
    return type.slug === gender;
  });

  const getLocaleName = (item) => {
    return item.localizations[locale].name;
  };

  const list = _.orderBy(genderTypeMenu[0].types, 'id', 'desc');
  const genderPath = gender === 'men' ? '/men' : '';

  const starProducts = {
    women: ['199', '204'],
    men: ['196'],
  };

  const findProduct = (id) => {
    return allProduct.find((node) => {
      return node.slug === id;
    });
  };

  const handleMenuClick = (item,link) => {
    //console.log(item)
    dispatch(onCollapseClick(null));

    const topLevel = gender === 'men' ? t('mens_care') : t('women_care');

    window.dataLayer.push({
      event: 'uaevent',
      event_name: 'menu_click',
      eventAction: 'select::header',
      eventCategory: 'main menu navigation',
      eventLabel: `${topLevel}::${getLocaleName(item)}`,
      breadcrumb: `${topLevel}::${getLocaleName(item)}`,      
      click_area: 'header',
      click_url: trackingLinkHelper(link),
      site_language: i18n.language,
      page_category: trackingHelper(window.location.pathname),
      brand: 'BIO',
      country: 'hk',
    }); 
  };

  const handleOtherMenuClick = (item,link) => {
    //console.log(item)
    dispatch(onCollapseClick(null));

    const topLevel = gender === 'men' ? t('mens_care') : t('women_care');

    let result = `${topLevel}::${t(item.name)}`;
    if(t(item.name) == t('mens_care') || t(item.name) == t('women_care')){
      result = t(item.name);
    }

    window.dataLayer.push({
      event: 'uaevent',
      event_name: 'menu_click',
      eventAction: 'select::header',
      eventCategory: 'main menu navigation',
      eventLabel: result,
      breadcrumb: result,      
      click_area: 'header',
      click_url: trackingLinkHelper(link),
      site_language: i18n.language,
      page_category: trackingHelper(window.location.pathname),
      brand: 'BIO',
      country: 'hk',
    }); 
  };

  return (
    <StyledNavigation
      className={`container-width ${layoutProps.pageContext.gender === 'men' ? 'container-width--men' : ''
        }`}
    >
      <div className={`row no-gutters position-relative flex-nowrap`}>
        {list.map((item, index) => {
          const parentSlug = `${genderPath}/category/${item.slug}`;

          const product = starProducts[gender][index] ? findProduct(starProducts[gender][index]) : null;

          return (
            <Item key={index} className={`position-static col-md`}>
              <StyledLink
                to={parentSlug}
                className={`btn-block p-1 text-uppercase text-center `}
                onClick={() => {handleMenuClick(item,parentSlug)}}
                activeClassName="active"
              >
                {getLocaleName(item)}
              </StyledLink>
              <SubList
                parent={parentSlug}
                parentName={getLocaleName(item)}
                lists={item}
                starProduct={product}
                className={`navigation__sub`}
                genderPath={genderPath}
              />
            </Item>
          );
        })}

        {otherMenu[0][gender].map((item, index) => {
          if (!item.active) {
            return null;
          }

          const itemlink = locale === 'en' ? item.pathEN : item.path;
          
          return (
            <Item
              key={index}
              locale={locale}
              className={`position-relative ${locale === 'en' && index === 4 ? 'col-md-2' : 'col-md '
                } ${item.className}`}
                
            >
              <StyledLink
                onClick={() => {handleOtherMenuClick(item,itemlink)}}
                to={itemlink}
                className={`btn-block p-1 text-uppercase text-center `}
                activeClassName="active"
              >
                <span>{t(item.name)}</span>
              </StyledLink>

              {item.children !== undefined && (
                <StaticSubList parentName={t(item.name)} lists={item.children} genderPath={genderPath} />
              )}
            </Item>
          );
        })}
      </div>
    </StyledNavigation>
  );
};

export default Navigation;

const useNavigationData = () => {
  /**
   * allMenu get data from api
   * allNavigationJson get data from local
   * allProduct get Star Products
   */
  const { allMenu, allNavigationJson, allProduct } = useStaticQuery(graphql`
    {
      allMenu {
        nodes {
          slug
          types {
            id
            slug
            localizations {
              en {
                name
              }
              zh {
                name
              }
            }
            categories {
              sequence
              slug
              localizations {
                en {
                  name
                }
                zh {
                  name
                }
              }
            }
            needs {
              sequence
              slug
              localizations {
                en {
                  name
                }
                zh {
                  name
                }
              }
            }
            series {
              sequence
              slug
              localizations {
                en {
                  name
                }
                zh {
                  name
                }
              }
            }
          }
        }
      }

      allNavigationJson {
        nodes {
          women {
            name
            path
            pathEN
            active
            className
            children {
              name
              path
              pathEN
              active
              className
            }
          }
          men {
            name
            path
            pathEN
            active
            className
          }
        }
      }

      allProduct(filter: { unique_id: { in: [199, 204, 209, 196] } }) {
        nodes {
          slug
          remoteImage {
            childImageSharp {
              fluid(maxWidth: 93) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          localizations {
            en {
              name
              star_description
            }
            zh {
              name
              star_description
            }
          }
        }
      }
    }
  `);
  return {
    typeMenu: allMenu.nodes,
    otherMenu: allNavigationJson.nodes,
    allProduct: allProduct.nodes,
  };
};

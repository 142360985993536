import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { StyledLink, StyledStaticSubList } from './StyledSubList';
import LayoutContext from '../../context/LayoutContext';
import { trackingHelper } from '../../utils/tracking-helper';
import { trackingLinkHelper } from '../../utils/tracking-link-helper';

const StaticSubList = ({ parentName, className, genderPath, lists }) => {
  const layoutProps = useContext(LayoutContext);
  const { gender } = layoutProps.pageContext;
  const { t,i18n } = useTranslation();
  const { locale } = useSelector((state) => state.common);

  const handleMenuClick = (item,link) => {
    console.log(item)

    const topLevel = gender === 'men' ? t('mens_care') : t('women_care');

    window.dataLayer.push({
      event: 'uaevent',
      event_name: 'menu_click',
      eventAction: 'select::header',
      eventCategory: 'main menu navigation',
      eventLabel: `${topLevel}::${item.join('::')}`,
      breadcrumb: `${topLevel}::${item.join('::')}`,      
      click_area: 'header',
      click_url: trackingLinkHelper(link),
      site_language: i18n.language,
      page_category: trackingHelper(window.location.pathname),
      brand: 'BIO',
      country: 'hk',
    }); 
  };

  return (
    <StyledStaticSubList>
      {lists.map((list, index) => {
        const itemlink = locale === 'en' ? list.pathEN : list.path;
        const listlink = itemlink.includes('http') ? itemlink : `${genderPath}${itemlink}`;

        return (
          <StyledLink
            key={index}
            to={listlink}
            className="p-1"
            onClick={() => {handleMenuClick([parentName,t(list.name)],listlink)}}
          >
            <span>{t(list.name)}</span>
          </StyledLink>
        );
      })}
    </StyledStaticSubList>
  );
};

export default StaticSubList;

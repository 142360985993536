import React, { useState } from 'react';

export const ThemeContext = React.createContext();

const ThemeStore = ({ children }) => {
  const [theme, setTheme] = useState('women');

  const swithTheme = () => {
    if (theme === 'women') {
      setTheme('men');
    } else {
      setTheme('women');
    }
  };

  return (
    <ThemeContext.Provider value={{ swithTheme, theme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeStore;

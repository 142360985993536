import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

import { menuOnClick } from '../../state/reducers/common';
import LayoutContext from '../../context/LayoutContext';
import Link from '../Link';
import LogoImage from '../../assets/images/men-biotherm-logo.png';
import { mediaQueries } from '../../utils/mediaQueries';
import './MenHeader.scss';
import { trackingHelper } from '../../utils/tracking-helper';
import { trackingLinkHelper } from '../../utils/tracking-link-helper';

const Container = styled.div`
  padding: 10px;

  ${mediaQueries('md')} {
    padding: 10px 10px 20px;
  }
`;

const TopContainer = styled.div`
  font-size: 12px;
`;

const LocaleContainer = styled.div`
  color: ${(props) => props.theme.activeColor};
  margin-right: 50px;
  a {
    font-weight: bold;
    color: inherit;
    margin: 0 5px;

    &:hover {
      text-decoration: none;
    }
  }

  ${mediaQueries('md')} {
    margin-right: 0;
    padding: 5px 12px;
    a {
      margin: 0;
    }
  }
`;

const Logo = styled(Link)`
  ${mediaQueries('md')} {
    width: 145px;
    img {
      display: block;
      max-width: 100%;
    }
  }
`;

const HeaderLink = styled(Link)`
  color: #a0a0a0;
  font-size: 12px;
  padding-right: 10px;
  border-right: 1px dotted #a0a0a0;

  &:not(:last-child) {
    margin-right: 10px;
  }

  &:hover {
    color: #ff0000;
  }
`;

const Hamburger = styled.div`
  background-color: #ff0000;
  border-radius: 50%;
  position: relative;
  width: 50px;
  height: 50px;

  .hamburger {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;

    padding-bottom: 10px;
  }
`;

const MenHeader = () => {
  const layoutProps = useContext(LayoutContext);
  const dispatch = useDispatch();
  const { locale, isMenuOpen, isMobileView } = useSelector(
    (state) => state.common
  );
  const { t,i18n } = useTranslation();

  let language = 'en';
  let originalPath = '/';

  try {
    language = locale === 'zh' ? 'en' : 'zh';
    originalPath = layoutProps.pageContext.originalPath;
  } catch (error) {}

  const handleLogoClick = () => {
    window.dataLayer.push({
      event: 'uaevent',
      event_name: 'logo_click',
      eventAction: 'select',
      eventCategory: 'main menu navigation',
      eventLabel: 'logo',
      site_language: i18n.language,
      page_category: 'homepage',
      brand: 'BIO',
      country: 'hk',
   });  
  };

  const handleMenuClick = (item,link) => {
    //console.log(link)
    //dispatch(menuOnClick(!isMenuOpen))

    const topLevel = t('mens_care');

    window.dataLayer.push({
      event: 'uaevent',
      event_name: 'menu_click',
      eventAction: 'select::header',
      eventCategory: 'main menu navigation',
      eventLabel: `${topLevel}::${item.join('::')}`,
      breadcrumb: `${topLevel}::${item.join('::')}`,      
      click_area: 'header',
      click_url: trackingLinkHelper(link),
      site_language: i18n.language,
      page_category: trackingHelper(window.location.pathname),
      brand: 'BIO',
      country: 'hk',
    }); 
  };
  //
  return (
    <header
      className={`container-width container-width--men position-relative`}
    >
      {isMobileView && (
        <LocaleContainer className="row no-gutters justify-content-end">
          <Link to={`/men${originalPath}`} language={language} onClick={() => {handleMenuClick([t('header_locale')],`/men${originalPath}`)}}>
            {t('header_locale')}
          </Link>
        </LocaleContainer>
      )}
      <Container className="row no-gutters justify-content-between">
        <Logo to="/men" onClick={() => {handleLogoClick()}}>
          <img src={LogoImage} alt="Biotherm" />
        </Logo>
        {isMobileView ? (
          <Hamburger onClick={() => dispatch(menuOnClick(!isMenuOpen))}>
            <div className={`hamburger`}>
              <div className="hamburger-box">
                <div className="hamburger-inner"></div>
              </div>
            </div>
          </Hamburger>
        ) : (
          <div className="row no-gutters flex-column justify-content-between align-items-end">
            <TopContainer className="row no-gutters">
              <LocaleContainer>
                <Link to={`/men${originalPath}`} language="zh" onClick={() => {handleMenuClick(['中文'],`/men${originalPath}`)}}>
                  中文
                </Link>
                /
                <Link to={`/men${originalPath}`} language="en" onClick={() => {handleMenuClick(['ENG'],`/men${originalPath}`)}}>
                  ENG
                </Link>
              </LocaleContainer>
              <div>
                <HeaderLink to="/men/store-locator" onClick={() => {handleMenuClick([t('store_location')],"/men/store-locator")}}>
                  {t('store_location')}
                </HeaderLink>
                <HeaderLink to="/men/contact-us" onClick={() => {handleMenuClick([t('contact_us')],"/men/contact-us")}}>{t('contact_us')}</HeaderLink>
              </div>
            </TopContainer>
            <div>
              <HeaderLink to="/about-biotherm" className="text-uppercase" onClick={() => {handleMenuClick([t('brand_story')],"/about-biotherm")}}>
                {t('brand_story')}
              </HeaderLink>
              <HeaderLink to="/membership" className="text-uppercase" onClick={() => {handleMenuClick([t('member_corner')],"/membership")}}>
                {t('member_corner')}
              </HeaderLink>
            </div>
          </div>
        )}
      </Container>
    </header>
  );
};

export default MenHeader;

import React, { useState } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { navigate } from 'gatsby';

import { onSearchChange } from '../../state/reducers/common';

const Search = styled.div`
  input {
    border-radius: 0;
    border: 1px solid #ffffff;
    border-right: 0;
  }

  button {
    background: #313131;
    border-radius: 0;
    border: 1px solid #ffffff;
  }
`;

const SearchBar = ({ classes }) => {
  const dispatch = useDispatch();
  const { t,i18n } = useTranslation();
  const { locale } = useSelector((state) => state.common);
  const [fieldValue, setFieldValue] = useState('');
  const localePath = locale === 'zh' ? '' : '/en';

  const onFieldChange = (e) => {
    if (e.key === 'Enter') {
      dispatch(onSearchChange(fieldValue));
      navigate(`${localePath}/search?q=${fieldValue}`);
    }
  };

  const onSeachSubmit = (e) => {
    //add dataLayer.push
    window.dataLayer.push({
      event: 'uaevent',
      event_name: 'internal_search',
      eventAction: 'search::manual',
      eventCategory: 'internal search',
      eventLabel: fieldValue,
      method: 'manual',
      search_keyword: fieldValue,
      site_language: i18n.language,
      page_category:'search',
      brand: 'BIO',
      country: 'hk',
    }); 
    
    e.preventDefault();

    if (fieldValue.trim() !== '') {
      dispatch(onSearchChange(fieldValue));
      navigate(`${localePath}/search?q=${fieldValue}`);
    }

    
  };

  return (
    <form onSubmit={(e) => onSeachSubmit(e)}>
      <Search className="input-group">
        <input
          className={`form-control-sm my-0 py-1 rded-border ${classes.input}`}
          type="text"
          placeholder={t('search')}
          aria-label={t('search')}
          value={fieldValue}
          onChange={(e) => setFieldValue(e.target.value)}
          onKeyDown={(e) => onFieldChange(e)}
        />
        <div className="input-group-append">
          <button
            className="btn-sm"
            id="button-addon2"
            type="submit"
          //
          >
            <i className="fas fa-search text-white" aria-hidden="true"></i>
          </button>
        </div>
      </Search>
    </form>
  );
};

export default SearchBar;

SearchBar.defaultProps = {
  settings: {},
  classes: {
    input: '',
  },
};

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { CollapseContext } from './Collapse';
import Link from '../Link';
import LayoutContext from '../../context/LayoutContext';
import { trackingHelper } from '../../utils/tracking-helper';
import { trackingLinkHelper } from '../../utils/tracking-link-helper';

const StyledPanel = styled.div`
  .panel {
    &__header {
      cursor: pointer;
    }

    &__content {
      height: 0;
      overflow: hidden;
      backface-visibility: hidden;
      padding: 0;
    }
  }
`;

const LinkContainer = styled.div`
  position: relative;
  span {
    cursor: pointer;
    position: absolute;
    height: 100%;
    right: 0;
    top: 0;
    z-index: 1;
    width: 50px;
  }
`;

const StyledLink = styled(Link)`
  display: block;

  &:hover {
    text-decoration: none;
  }
`;

const Panel = ({ children, header, classes, panelKey, pathContent }) => {
  const layoutProps = useContext(LayoutContext);
  const { gender } = layoutProps.pageContext;
  const { activePanel, setActivePanel } = useContext(CollapseContext);
  const activeClass = activePanel.includes(panelKey) ? 'active' : '';
  const { t,i18n } = useTranslation();

  const handleMenuClick = (item,link) => {
    const topLevel = gender === 'men' ? t('mens_care') : t('women_care');

    window.dataLayer.push({
      event: 'uaevent',
      event_name: 'menu_click',
      eventAction: 'select::header',
      eventCategory: 'main menu navigation',
      eventLabel: `${topLevel}::${item.join('::')}`,
      breadcrumb: `${topLevel}::${item.join('::')}`,      
      click_area: 'header',
      click_url: trackingLinkHelper(link),
      site_language: i18n.language,
      page_category: trackingHelper(window.location.pathname),
      brand: 'BIO',
      country: 'hk',
    }); 
  };

  return (
    <StyledPanel
      className={['panel', classes.container, activeClass].join(' ')}
    >
      {pathContent !== null && (
        <LinkContainer>
          <StyledLink
            to={pathContent.path}
            className={`panel__header ${classes.header}`}
            onClick={() => {handleMenuClick([t(pathContent.name)],pathContent.path)}}
          >
            {t(pathContent.name)}
          </StyledLink>
          <span onClick={() => setActivePanel(panelKey)} />
        </LinkContainer>
      )}

      {header && (
        <div
          className={`panel__header ${classes.header}`}
          onClick={() => setActivePanel(panelKey)}
        >
          {header}
        </div>
      )}
      <div className={`panel__content ${classes.content}`}>{children}</div>
    </StyledPanel>
  );
};

Panel.propTypes = {
  children: PropTypes.node,
  __TYPE: PropTypes.string,
};

Panel.defaultProps = {
  header: null,
  pathContent: null,
  __TYPE: 'Panel',
  classes: {
    container: '',
    header: '',
    content: '',
  },
};

export default Panel;

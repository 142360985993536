export const breakpoints = {
  sm: 479,
  md: 767,
  lg: 991,
  xl: 1919,
};

export const mediaQueries = (key) => {
  return `@media (max-width: ${
    breakpoints[key] !== undefined ? breakpoints[key] : key
  }px) `;
};

export const calcvw = (absSize, breakpoint) => {
  return `${(absSize / breakpoint) * 100}vw`;
};

export const calcRem = (size, base = 16) => {
  return `${size / base}rem`;
};

import React from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useStaticQuery, graphql } from 'gatsby';
import { useSelector } from 'react-redux';
import WechatQR from '../../assets/images/QRcode/wechat_QR.jpg';
import { trackingHelper } from '../../utils/tracking-helper';

import {
  Container,
  BoxContent,
  Box,
  Header,
  FooterLink,
  FooterHref,
  BottomContainer,
  BottonLinks,
  FooterSubLink,
  FooterCookieLink,
  Tnc,
} from './StyledFooter';

import BackToTop from '../BackToTop';
import { trackingLinkHelper } from '../../utils/tracking-link-helper';

const useSitemapData = () => {
  const { allSiteFooterJson } = useStaticQuery(graphql`
    query {
      allSiteFooterJson {
        nodes {
          women {
            name
            displayPosition
            items {
              name
              link
            }
          }
          men {
            name
            displayPosition
            items {
              name
              link
            }
          }
        }
      }
    }
  `);
  return allSiteFooterJson.nodes[0];
};

const classes = {
  women: {
    footer: 'container-width pt-md-3',
    container: `pt-4 pb-3 text-light content-padding`,
    content: 'row no-gutters justify-content-between',
    box: '',
  },
  men: {
    footer: 'container-width container-width--men pt-md-4',
    container: 'pt-3 pb-3 content-padding',
    content: 'row',
    box: 'col-12 col-md-3',
  },
};

const Footer = ({ gender }) => {
  const { t, i18n } = useTranslation();
  const { isMobileView } = useSelector((state) => state.common);
  const detail = useSitemapData()[gender];
  const footerClasses = classes[gender];

  const footerData = _.filter(detail, { displayPosition: 'footer' });
  const bottomData = _.filter(detail, { displayPosition: 'bottom' });

  const handleFooterClick = (name, link) => {
    window.dataLayer.push({
      event: 'uaevent',
      event_name: 'social_interaction',
      eventAction: 'visit',
      eventCategory: name,
      eventLabel: link,
      social_action: 'visit',
      social_network: name,
      brand: 'BIO',
      site_language: i18n.language,
      country: 'hk',
      page_category: trackingHelper(window.location.pathname),
    });
  };

  const handleMenuClick = (item, link) => {
    //console.log(item)

    const topLevel = gender === 'men' ? t('mens_care') : t('women_care');

    window.dataLayer.push({
      event: 'uaevent',
      event_name: 'menu_click',
      eventAction: 'select::footer',
      eventCategory: 'main menu navigation',
      eventLabel: `${topLevel}::${item.join('::')}`,
      breadcrumb: `${topLevel}::${item.join('::')}`,
      click_area: 'footer',
      click_url: trackingLinkHelper(link),
      site_language: i18n.language,
      page_category: trackingHelper(window.location.pathname),
      brand: 'BIO',
      country: 'hk',
    });
  };

  return (
    <footer className={footerClasses.footer}>
      {!isMobileView && (
        <>
          <BackToTop />
          <Container className={footerClasses.container}>
            <div className={footerClasses.content}>
              {footerData.map((data, index) => {
                return (
                  <Box key={index} className={footerClasses.box}>
                    <BoxContent>
                      <Header>{t(data.name)}</Header>
                      <ul className="list-unstyled small">
                        {data.items.map((item) => (
                          <li key={item.name} className="mb-1">
                            {item.link === "wechatQR" || item.link.includes('facebook') || item.link.includes('youtube') ? (
                              <FooterHref href={item.link === "wechatQR" ? WechatQR : item.link} target="_blank" onClick={() => handleFooterClick(item.name, item.link === "wechatQR" ? WechatQR : item.link)}>
                                {t(item.name)}
                              </FooterHref>
                            ) : (
                              <FooterLink to={item.link} onClick={() => { handleMenuClick([t(item.name)], item.link) }}>
                                {t(item.name)}
                              </FooterLink>
                            )}
                          </li>
                        ))}
                      </ul>
                    </BoxContent>
                  </Box>
                );
              })}
            </div>
          </Container>
        </>
      )}

      <BottomContainer
        className={`${isMobileView ? '' : 'content-padding'
          } py-md-4 py-3  small`}
      >
        <div className="row no-gutters justify-content-md-center justify-content-between flex-nowrap align-items-top">
          <BottonLinks>
            {bottomData.map((data) => {
              return data.items.map((item, index) => (
                item.link === "/cookie" ? (
                  <FooterCookieLink className="optanon-show-settings">{t(item.name)}</FooterCookieLink>
                ) : (
                  <FooterSubLink key={index} to={item.link} onClick={() => { handleMenuClick([t(item.name)], item.link) }}>
                    {t(item.name)}
                  </FooterSubLink>
                )
              ));
            })}
          </BottonLinks>
          <Tnc className="mx-2 text-nowrap">© BIOTHERM 2021</Tnc>
        </div>
      </BottomContainer>
    </footer>
  );
};

export default Footer;

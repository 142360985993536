import styled, { css } from 'styled-components';
import { mediaQueries } from '../../utils/mediaQueries';

export const Container = styled.div`
  width: 280px;
  margin: 0 0 0 auto;

  img{
    width:100%;
  }
  ${mediaQueries('md')} {
    width:50%;
  }
`;


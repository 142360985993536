import _ from 'lodash';
import React, { useState, useEffect } from 'react';

const getWidth = () => {
  if (typeof window !== `undefined`) {
    return (
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth
    );
  }
};

export const useCurrentWitdh = () => {
  // save current window width in the state object
  let [width, setWidth] = useState(getWidth());

  // in this case useEffect will execute only once because
  // it does not have any dependencies.
  useEffect(() => {
    const resizeListener = () => {
      // change width from the state object
      setWidth(getWidth());
    };
    // set resize listener
    window.addEventListener('resize', resizeListener);

    // clean up function
    return () => {
      // remove resize listener
      window.removeEventListener('resize', resizeListener);
    };
  }, []);

  return width;
};

export const getImages = (images) => {
  const imagesList = {};

  for (const image of images) {
    imagesList[image.name] = image.childImageSharp.fluid;
  }

  return imagesList;
};

/**
 * Ensures component type is not overwritten
 *
 * @param {string} expectedType - The expected type to validate against
 * @returns {func} Custom PropTypes validation function
 */
export const typeValidation = (expectedType) => (
  props,
  propName,
  componentName
) => {
  if (props[propName] !== expectedType) {
    return new Error(
      `'${propName}' in '${componentName}'\n\nYou may NOT pass in a prop value for '${propName}'. `
    );
  }
};

/**
 * Gets the string type of the component or core html (JSX) element. React Fragments will return type 'react.fragment'. Priority will be given to the prop '__TYPE'.
 *
 * @param {ReactNode} component - The component to type check
 * @returns {string} - The string representation ofthe type
 */
export const typeOfComponent = (component) =>
  component?.props?.__TYPE ||
  component?.type
    ?.toString()
    .replace('Symbol(react.fragment)', 'react.fragment') ||
  undefined;

/**
 * Gets all children by specified type. This function will check the prop '__TYPE' first and then the 'type' string to match core html elements. To find a React Fragment, search for type 'react.fragment'.
 *
 * @param {ReactNode} children - JSX children
 * @param {string[]} types - Types of children to match
 * @returns {ReactNode[]} - Array of matching children
 * @example
 * // Finds all occurrences of ToDo (custom component), div, and React Fragment
 * getChildrenByType(children, ['ToDo', 'div', 'react.fragment']);
 */
export const getChildrenByType = (children, types) =>
  React.Children.toArray(children).filter(
    (child) => types.indexOf(typeOfComponent(child)) !== -1
  );

export const orderBySequence = (list, key = null) => {
  if (key === null) {
    return _.orderBy(list, ['sequence'], ['asc']);
  }

  return _.orderBy(list[key], ['sequence'], ['asc']);
};

export const getSubType = (item) => {
  let type = null;

  if (item?.categories) {
    type = 'categories';
  } else if (item?.needs) {
    type = 'needs';
  } else if (item?.series) {
    type = 'series';
  }

  return type;
};

import React, { useContext, useEffect } from 'react';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import { ThemeContext } from '../../context/ThemeStore';
import { css } from 'styled-components';

const themes = {
  women: {
    name: 'women',
    background: '#ffffff',
    headerBgColor: '#313131',
    primaryColor: '#313131',
    textColor: '#000000',
    headerColor: '#0b72b5',
    descColor: '#8397a8',
    activeColor: '#313131',
    // Form
    labelColor: '#0b72b5',
    formBorder: '1px solid #c4d1d9',
    submitBgColor: '#116787',
    submitHoverBgColor: '#5ba0b7',
    fombBgColor: 'linear-gradient(to bottom, #ebf4fb 33%, #f6fcfc 100%)',
    // navigation
    navContainer: css`
      border-bottom: 1px solid #313131;
    `,
    button: {
      bgColor: '#3471B0',
      bgHoverColor: '#fff',
    },
    // Products
    products: {
      nameColor: '#0b72b5',
      nameMinHeihgt: '100px',
      descMinHeihgt: '95px',
      border: '1px solid #e0e0e0',
    },
    prodcut: {
      nameColor: '#004b86',
      descColor: '#8397a8',
      panelHeaderBg: 'linear-gradient(#ffffff, #ececec)',
      panelContentBg: 'linear-gradient(#ffffff, #ececec)',
      panelContentColor: '#666666',
      border: '1px solid #c0c0c0',
      arrowColor: '#c0c0c0',
    },
    relatedProduct: {
      containerSize: '85%',
      titleColor: '#647178',
      boxSize: '245px',
      nameColor: '#0b72b5',
      buttonColor: 'white',
      buttonHoverColor: 'white',
      buttonBg: '#3471B0',
      buttonHoverBg: '#3471B0',
    },
    slideMenu: {
      bgColor: '#313131',
      activeColor: '#ffffff',
      activeBgColor: '#116787',
    },
    navigation: {
      tabColor: '#ffffff',
      tabActiveColor: '#116787',
      tabBg: '#116787',
      tabActiveBg: '#ffffff',
      titleColor: '#0b72b5',
      buttonColor: '#fff',
      buttonBg:'#3471B0',
      buttonHoverBg: '#3471B0',
    },
    home: {
      subCollapseActiveBg: '#176987;',
      subContentBg: '#ffffff',
      subCollapseActiveColor: '#ffffff',
      subContentColor: '#00447b',
    },
  },
  men: {
    name: 'men',
    background: '#131313',
    headerBgColor: '#131313',
    primaryColor: '#ff0000',
    textColor: '#ffffff',
    headerColor: '#ff0000',
    descColor: '#ffffff',
    activeColor: '#ff0000',
    // Form
    labelColor: '#ffffff',
    formBorder: '1px solid #333333',
    submitBgColor:
      'linear-gradient(180deg, rgba(252,64,63,1) 0%, rgba(208,1,3,1) 100%)',
    submitHoverBgColor:
      'linear-gradient(0deg, rgba(252,64,63,1) 0%, rgba(208,1,3,1) 100%)',
    fombBgColor: 'transparent',
    // navigation
    navContainer: css`
      border-top: 1px solid #555555;
      border-bottom: 1px solid #555555;
    `,
    button: {
      bgColor: '#3471B0',
      bgHoverColor: '#fff',
    },
    products: {
      nameColor: '#ffffff',
      nameMinHeihgt: '80px',
      descMinHeihgt: '50px',
      border: '1px solid #333',
    },
    prodcut: {
      nameColor: '#ff0000',
      descColor: '#ffffff',
      panelHeaderBg:
        'linear-gradient( #333 /*{a-bar-background-start}*/, #000 /*{a-bar-background-end}*/)',
      panelContentBg:
        'linear-gradient( #333 /*{a-bar-background-start}*/, #000 /*{a-bar-background-end}*/)',
      panelContentColor: '#ffffff',
      border: '1px solid rgba(255,255,255,.3)',
      arrowColor: '#ffffff',
    },
    relatedProduct: {
      containerSize: '100%',
      titleColor: '#ff0000',
      boxSize: '300px',
      nameColor: '#ffffff',
      buttonColor: 'white',
      buttonHoverColor: 'white',
      buttonBg: '#3471B0',
      buttonHoverBg: '#3471B0',
    },
    slideMenu: {
      activeColor: '#d20002',
      activeBgColor: '#ffffff',
      bgColor:
        'linear-gradient(to right,  rgba(219,0,0,1) 0%,rgba(167,0,0,1) 100%)',
    },
    navigation: {
      tabColor: '#ffffff',
      tabActiveColor: '#ffffff',
      tabBg: '#333',
      tabActiveBg: '#db0000',
      titleColor: '#9f9e9e',
      buttonColor: '#fff',
      buttonBg:'#3471B0',
      buttonHoverBg: '#3471B0',
    },
    home: {
      subCollapseActiveBg: '#1a1a1a',
      subContentBg: '#333',
      subCollapseActiveColor: '#9f9e9e',
      subContentColor: '#ffffff',
    },
  },
};

const GlobalStyle = createGlobalStyle`
  body{
     background:${(props) => props.theme.background};
  }

  h1 {
    font-size: 40px;
    text-transform: uppercase;
    color: ${(props) => props.theme.headerColor};
    font-family: "微軟正黑體", sans-serif;
    font-weight: bold;

    &:lang(en) {
      font-family: 'futural', 'futurastd', 'futurastd_IE', Arial, Helvetica, sans-serif;
      font-weight: normal;
    }
  }
`;

const Theme = ({ children, gender }) => {
  const { theme, swithTheme } = useContext(ThemeContext);
  useEffect(() => {
    if (theme !== gender) {
      swithTheme(gender);
    }
  }, []);

  return (
    <ThemeProvider theme={themes[theme]}>
      <GlobalStyle />
      {children}
    </ThemeProvider>
  );
};

export default Theme;

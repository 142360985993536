import styled, { css } from 'styled-components';
import Link from '../Link';
import { calcRem } from '../../utils/mediaQueries';
import BgStarProduct from '../../assets/images/navigation/bg-star-product.jpg';
import BgManStarProduct from '../../assets/images/navigation/bg-star-product-men.jpg';

export const StyledLink = styled(Link)`
  display: block;
  position: relative;
  color: ${(props) => (props.theme.name === 'women' ? '#333' : '#fff')};
  font-size: ${(props) => (props.locale === 'zh' ? calcRem(15) : calcRem(14))};
  font-weight: bold;
  text-decoration: none;
  transition: color 0.2s;
  font-family: 'futural', Arial, '微軟正黑體', Helvetica, sans-serif;
  text-align: center;

  &:hover,
  &.active {
    color: ${(props) => (props.theme.name === 'women' ? '#0cbbd4' : '#db0000')};
    text-decoration: none;
  }
`;

export const StyledStaticSubList = styled.div`
  display: none;
  position: absolute;
  background-color: ${(props) => props.theme.background};
  width: 100%;
  z-index: 100;

  ${StyledLink}:first-child {
    border-top: 1px solid #ccc;
  }

  ${StyledLink}:not(:last-child) {
    border-bottom: 1px solid #ccc;
  }
`;

export const Item = styled.div`
  position: relative;

  &:hover .navigation__sub {
    display: block !important;
  }

  &:hover {
    ${StyledStaticSubList} {
      display: block;
    }
  }

  &.online-shop {
    ${(props) =>
    props.locale === 'zh' &&
    css`
        background-color: #313131;
        a:before {
          content: '';
        }
      `}
  }

  &.mens-care {
    background-color: #879ba9;
    a:before {
      content: none;
    }
  }

  &:not(:last-child) > a:before {
    content: '';
    position: absolute;
    width: 1px;
    height: 10px;
    right: 0;
    top: 50%;
    margin: -6px 0;
    background-color: #ccc;
  }

  // add red tag
  ${(props) =>
    props.locale === 'zh' ? '&.online-shop, &.mens-care' : '&.mens-care'} {
    ${StyledLink} {
      color: #ffffff;
    }

    & > ${StyledLink} span {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        width: 4px;
        height: 12px;
        transform: translateY(-50%);
        top: 50%;
        left: -10px;
        background-color: #f00;
      }
    }
  }
`;

export const StyledNavigation = styled.div`
  & > .row {
    ${(props) => props.theme.navContainer};
  }
`;

export const StyledSubList = styled.div`
  position: absolute;
  background-color: ${(props) => props.theme.background};
  z-index: 200;
  width: 100%;
  left: 0;
  border: 1px solid #7d7d7d;
  min-height: 400px;

  .list-container {
    border-right: 1px solid #7d7d7d;
    padding: 15px;

    &:nth-child(3) {
      border-right: 0;
    }
  }

  h5 {
    color: ${(props) => props.theme.navigation.titleColor};
    text-align: left;
    margin-bottom: 5px;
    text-transform: uppercase;
    font-size: 17px;
  }

  ul {
    list-style: none;
    margin-left: 0;
    padding-left: 1.5rem;

    li {
      text-align: left;

      &::before {
        ${(props) =>
    props.theme.name === 'women'
      ? ` content: '\\2022';`
      : ` content: '＞';`}

        color: ${(props) => props.theme.primaryColor};
        font-size: 12px;

        font-weight: bold;
        display: inline-block;
        width: 1em;
        margin-left: -1em;
        padding-right: 15px;
      }

      a {
        color: ${(props) => props.theme.textColor};
        font-size: 13px;
        &:hover {
          color: ${(props) => props.theme.primaryColor};
        }
      }
    }
  }

  .star-product {
    ${(props) =>
    props.theme.name === 'men'
      ? ` background: url(${BgManStarProduct}) no-repeat;`
      : ` background: url(${BgStarProduct}) no-repeat;`}

    width: 272px;
    color: ${(props) => props.theme.primaryColor};

    .header {
      font-weight: bold;
      position: absolute;
      right: 12px;
      top: 5px;
      font-size: 18px;
    }

    &__container {
      padding-top: 80px;
      text-align: left;
    }

    &__image {
    }

    &__detail {
      padding-right: 10px;
      color: ${(props) => (props.theme.name === 'men' ? '#ffffff' : '#0b72b5')};
    }

    &__name {
      font-weight: bold;
      margin-top: 30px;
      margin-bottom: 10px;
    }

    &__desc {
      font-size: 12px;
      margin-bottom: 20px;
    }

    &__link {
      font-size: 12px;
      font-weight: bold;
      text-transform:uppercase; 
      padding: 5px 20px;
      color: ${(props) => props.theme.navigation.buttonColor};
      text-decoration: none;
      background: ${(props) => props.theme.navigation.buttonBg};
      border-radius: 20px;
      &:after{
        content:"";
        border: solid ${(props) => props.theme.navigation.buttonColor};
        border-width: 0 1px 1px 0;
        display: inline-block;
        padding: 3px;
        transform: rotate(-45deg);
      }
      &:hover {
        background: ${(props) => props.theme.navigation.buttonHoverBg};
      }
    }
  }
`;

export const StarProductImage = styled.div`
  width: 93px;
  text-align: center;
  .gatsby-image-wrapper {
    margin: 0 auto;
    & > div {
      padding-bottom: 105px !important;
    }
  }

  picture {
    img {
      object-fit: contain !important;
      max-height: 105px;
    }
  }
`;

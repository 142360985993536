import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image/withIEPolyfill';
import * as styles from './SNSLink.module.scss';
import WechatQR from '../../assets/images/QRcode/wechat_QR.jpg';

const SNSLink = (props) => {
  return (
    <a
      className="position-relative d-block"
      href={props.path === "wechatQR" ? WechatQR : props.path}
      target="_blank"
      rel="noopener noreferrer"
      {...props}
    >
      <Img fluid={props.image} alt={props.id} className={styles.Image} />
    </a>
  );
};

export default SNSLink;

SNSLink.propTypes = {
  path: PropTypes.string.isRequired,
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

SNSLink.defaultProps = {
  path: '/',
};

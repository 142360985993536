import React, { useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import { useTranslation } from 'react-i18next';

import { menuOnClick } from '../../state/reducers/common';
import LayoutContext from '../../context/LayoutContext';
import Link from '../Link';
import SearchBar from '../SearchBar/SearchBar';
import ArrowImg from '../../assets/images/global/collapse_arrow.png';
import Collapse, { Panel } from '../Collapse';
import { trackingHelper } from '../../utils/tracking-helper';
import { trackingLinkHelper } from '../../utils/tracking-link-helper';

const useSlideMenuData = () => {
  const { allSlideMenuJson } = useStaticQuery(graphql`
    query {
      allSlideMenuJson {
        nodes {
          id
          women {
            name
            path
            active
            children {
              name
              path
              pathEN
              active
            }
          }
          men {
            name
            path
            active
          }
        }
      }
    }
  `);

  return allSlideMenuJson.nodes;
};

const Backdrop = styled.div`
  position: absolute;
  height: 100vh;
  width: 100%;
  z-index: 9900;
  top: 0;
  left: 0;
`;

const Container = styled.div`
  position: fixed;
  height: 100%;
  width: 0;
  z-index: 10;

  &.active {
    width: 100%;
    ${Backdrop} {
      width: 100%;
    }
  }
`;

const Menu = styled.div`
  position: fixed;
  height: 100%;
  right: 0;
  top: 0;
  background: ${(props) => props.theme.slideMenu.bgColor};
  transform: translate3d(272px, 0, 0);
  width: 0;
  z-index: 9999;
  backface-visibility: hidden;
  transition: all 0.5s;

  &.active {
    transform: translate3d(0, 0, 0);
    width: 272px;
    box-shadow: -5px 0 5px rgb(0 0 0 / 15%);
  }
`;

const StyledLink = styled(Link)`
  display: block;
  padding: 15px 20px;
  color: #ffffff;
  transition: all 0.3s;
  text-transform: uppercase;

  border-top: 1px solid rgba(255, 255, 255, 0.3);
  border-bottom: 1px solid rgba(50, 50, 50, 0.5);
  position: relative;

  &.active,
  &:hover,
  &:active,
  &:focus {
    background-color: ${(props) => props.theme.slideMenu.activeBgColor};
    color: ${(props) => props.theme.slideMenu.activeColor};

    &::after {
      /* color: ${(props) => props.theme.slideMenu.activeColor}; */

      /* background-position: ${(props) =>
        props.theme.name === 'women' ? ' -23px -17px' : '-3px -17px'}; */
    }
  }

  &:hover {
    text-decoration: none;
  }

  /* &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    width: 6px;
    height: 12px;
    background: url(${ArrowImg}) no-repeat;
    background-position: -23px -17px;
    text-align: right;
  } */
`;

const SearchContainer = styled.div`
  padding: 10px;
`;

const StyledCollapse = styled(Collapse)`
  .panel__header {
    color: #ffffff;
    font-size: 1rem;
    border-top: none;
    border-color: rgba(50, 50, 50, 0.5);
  }

  .panel__content {
    a {
      padding-left: 2rem;
      background-color: #ffffff;
      color: ${(props) => props.theme.activeColor};

      &.active,
      &:hover,
      &:active,
      &:focus {
        background-color: ${(props) => props.theme.slideMenu.activeBgColor};
        color: ${(props) => props.theme.slideMenu.activeColor};

        &::after {
          /* color: ${(props) => props.theme.slideMenu.activeColor}; */

          /* background-position: ${(props) =>
            props.theme.name === 'women' ? ' -23px -17px' : '-3px -17px'}; */
        }
      }
    }
  }
`;

const SlideMenu = ({ hasSearch }) => {
  const layoutProps = useContext(LayoutContext);
  const { t,i18n } = useTranslation();
  const { isMenuOpen, isMobileView, locale } = useSelector((state) => state.common);
  const dispatch = useDispatch();

  const slideMenuData = useSlideMenuData()[0][layoutProps.pageContext.gender];

  const { gender } = layoutProps.pageContext;

  if (isMenuOpen && !isMobileView) {
    dispatch(menuOnClick(false));
  }

  const handleMenuClick = (item,link) => {
    //console.log(item)
    dispatch(menuOnClick(false))

    const topLevel = gender === 'men' ? t('mens_care') : t('women_care');

    window.dataLayer.push({
      event: 'uaevent',
      event_name: 'menu_click',
      eventAction: 'select::burger menu',
      eventCategory: 'main menu navigation',
      eventLabel: `${topLevel}::${item.join('::')}`,
      breadcrumb: `${topLevel}::${item.join('::')}`,      
      click_area: 'burger menu',
      click_url: trackingLinkHelper(link),
      site_language: i18n.language,
      page_category: trackingHelper(window.location.pathname),
      brand: 'BIO',
      country: 'hk',
    });
  };

  return (
    <Container className={isMenuOpen ? 'active' : ''}>
      <Backdrop
        onClick={() => {
          dispatch(menuOnClick(false));
        }}
      />
      <Menu className={isMenuOpen ? 'active' : ''}>
        <div>
          {hasSearch && isMenuOpen && (
            <SearchContainer>
              <SearchBar classes={{ input: 'form-control' }} />
            </SearchContainer>
          )}

          {slideMenuData.map((item, index) => {
            const children = item.children === undefined ? [] : item.children;

            if (!item.active) {
              return null;
            }

            if (children.length > 0) {
              return (
                <StyledCollapse key={index}>
                  <Panel key={0} panelKey={0} pathContent={item}>
                    {item.children.map((child, index) => {
                      if (!child.active) {
                        return null;
                      }

                      const itemlink = locale === 'en' ? child.pathEN ? child.pathEN : child.path : child.path;

                      return (
                        <StyledLink
                          key={index}
                          to={itemlink}
                          activeClassName="active"
                          onClick={() => {handleMenuClick([t(child.name)],itemlink)}}
                        >
                          {t(child.name)}
                        </StyledLink>
                      );
                    })}
                  </Panel>
                </StyledCollapse>
              );
            } else {
              if (!item.active) {
                return null;
              }

              return (
                <StyledLink
                  key={item.name}
                  to={item.path}
                  activeClassName="active"
                  onClick={() => {handleMenuClick([t(item.name)],item.path)}}
                >
                  {t(item.name)}
                </StyledLink>
              );
            }
          })}
        </div>
      </Menu>
    </Container>
  );
};

export default SlideMenu;

SlideMenu.defaultProps = {
  hasSearch: false,
};

import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
const BackToTopContainer = styled.div`
  border-top: ${(props) =>
    props.theme.name === 'women' ? '2px solid #dfdfdf' : '1px solid #555555'};

  svg {
    color: ${(props) => props.theme.headerColor};
    margin-left: 5px;
  }

  div {
    cursor: pointer;
    font-size: 14px;
    color: ${(props) =>
      props.theme.name === 'women' ? '#0b72b5' : '#aeaeae;'};

    &:hover {
      color: ${(props) => props.theme.headerColor};
      text-decoration: none;
    }
  }
`;

const BackToTop = () => {
  const { t } = useTranslation();
  const scrollToTop = () => {
    if (typeof window !== `undefined`) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  return (
    <BackToTopContainer className="text-right pb-3">
      <div className="mb-3"></div>
      <div className="small py-2 px-3" onClick={scrollToTop}>
        {t('back_to_top')}
        <i className="fa fa-chevron-circle-up" aria-hidden="true"></i>
      </div>
    </BackToTopContainer>
  );
};

export default BackToTop;

import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';

import ThemeStore from '../../context/ThemeStore';
import Theme from '../Theme';
import Header from '../Header';
import Footer from '../Footer/Footer';
import SEO from '../SEO';
import Navigation from '../Navigation';
import LayoutContext from '../../context/LayoutContext';
import { changeLocale, changeIsMobile } from '../../state/reducers/common';
import CookiesBar from '../CookiesBar';
import MenHeader from '../MenHeader';
import SlideMenu from '../SlideMenu';
import Breadcrumb from '../Breadcrumb';

// import bgImage from '../../assets/images/men/HabillageSite.jpeg';
// import bgImage2 from '../../assets/images/men/HabillageSite_Bar_R.jpeg';

const MenBg = styled.div`
  position: fixed;
  z-index: -1;
  height: 100vh;
  width: 100%;
  background-size: auto 115%;
`;

const MenBg3 = styled.div`
  position: fixed;
  z-index: -1;
  height: 100vh;
  width: 100%;
  background-size: auto 100%;
  background-position: right;
`;

const MenBg2 = styled.div`
  background-color: #131313;
  z-index: -1;
  position: fixed;
  height: 100vh;
  width: 70%;
  transform: translateX(-50%);
  left: 50%;

  @media screen and (max-width: 1500px) {
    width: 1017px;
  }
`;

const MenLink = styled.div`
  position: fixed;
  height: 100vh;
  top: 0;
  left: 50%;
  width: 1017px;
  z-index: -1;
  transform: translateX(-50%);
  border-left: 1px solid #555555;
  border-right: 1px solid #555555;
  background-color: #131313;

  @media screen and (max-width: 767px) {
    display: none;
  }
`;

const MenBackground = () => {
  return (
    <>
      <MenBg />
      <MenBg3 />
      <MenBg2 />
      <MenLink />
    </>
  );
};

const Layout = ({
  children,
  fullwidth,
  padding,
  seoSettings,
  hasBreadcrumb,
  breadcrumb,
}) => {
  const layoutProps = useContext(LayoutContext);
  let mainClass = [];
  const dispatch = useDispatch();
  const isTabletOrMobile = useMediaQuery({ maxWidth: 767 });
  const { locale, isMobileView } = useSelector((state) => state.common);
  const gender = layoutProps.pageContext.gender;

  if (fullwidth) {
    mainClass.push('full-width');
  } else {
    mainClass.push('container-width');
    if (gender === 'men') {
      mainClass.push('container-width--men');
    }

    if (padding) {
      mainClass.push('content-padding');
    }
  }

  if (isTabletOrMobile !== isMobileView) {
    dispatch(changeIsMobile(isTabletOrMobile));
  }

  useEffect(() => {
    if (locale !== layoutProps.pageContext.locale) {
      dispatch(changeLocale(layoutProps.pageContext.locale));
    }
  }, []);

  return (
    <>
      <ThemeStore>
        <Theme gender={gender}>
          {gender === 'men' && <MenBackground />}
          <SEO {...seoSettings} />
          <SlideMenu hasSearch={gender === 'men' ? false : true} />
          {gender === 'women' ? <Header /> : <MenHeader />}
          {!isMobileView && <Navigation />}
          <main className={mainClass.join(' ')}>
            {hasBreadcrumb && !isMobileView && (
              <Breadcrumb
                customList={breadcrumb}
                defaultList={layoutProps.pageContext}
              />
            )}
            {children}
          </main>
          <Footer gender={gender} />
          <CookiesBar />
        </Theme>
      </ThemeStore>
    </>
  );
};

export default Layout;

Layout.propTypes = {
  fullwidth: PropTypes.bool,
  padding: PropTypes.bool,
  hasBreadcrumb: PropTypes.bool,
};

Layout.defaultProps = {
  fullwidth: false,
  padding: true,
  hasBreadcrumb: true,
  breadcrumb: [],
};

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useLocation } from '@reach/router';
import { useStaticQuery, graphql } from 'gatsby';
import { useSelector } from 'react-redux';
// import bootstrapStyle from 'bootstrap/dist/css/bootstrap.min.css';
// import fortawesomeSolidStyle from '@fortawesome/fontawesome-free/css/solid.min.css';
// import fortawesomeStyle from '@fortawesome/fontawesome-free/css/fontawesome.min.css';
// import fontStyle from '../../assets/css/font.css';
// import globalStyle from '../../assets/scss/global.scss';
// import mainStyle from '../../assets/scss/main.scss';

import { removeTrailingSlash } from '../../utils/gatsby-node-helpers';

const SEO = ({ title, description, image, article, keywords = '' }) => {
  const { pathname } = useLocation();
  const { site } = useStaticQuery(query);

  const {
    defaultTitle,
    titleTemplate,
    defaultDescription,
    siteUrl,
    defaultImage,
    twitterUsername,
  } = site.siteMetadata;

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `https://www.biotherm.com.hk${image || defaultImage}`,
    url: `https://www.biotherm.com.hk${pathname}`,
    keywords: keywords,
  };

  const { locale } = useSelector((state) => state.common);

  useEffect(() => {
    document.getElementById('onetrust')?.remove();
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.id = "onetrust";
    script.async = true;
    script.src = "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js";
    script.dataset.domainScript = "0191078e-f116-7da0-976d-2094f69f2e33";
    //add the script to the head
    document.head.appendChild(script);
  }, [locale]);

  return (
    <Helmet
      title={seo.title}
      titleTemplate={titleTemplate}
      htmlAttributes={{
        lang: locale,
      }}
    >
      <script
        id="onetrust"
        src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
        type="text/javascript"
        charset="UTF-8"
        data-domain-script="0191078e-f116-7da0-976d-2094f69f2e33"
      ></script>
      <script
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `
            function OptanonWrapper() { }
          `,
        }}
      ></script>
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      <meta name="keywords" content={seo.keywords} />
      {seo.url && (
        // <meta property="og:url" content={removeTrailingSlash(seo.url)} />
        <meta property="og:url" content={seo.url} />
      )}
      {(article ? true : null) && <meta property="og:type" content="article" />}
      {seo.title && <meta property="og:title" content={seo.title} />}
      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}
      {seo.image && <meta property="og:image" content={seo.image} />}
      <meta name="twitter:card" content="summary_large_image" />
      {twitterUsername && (
        <meta name="twitter:creator" content={twitterUsername} />
      )}
      {seo.title && <meta name="twitter:title" content={seo.title} />}
      {seo.description && (
        <meta name="twitter:description" content={seo.description} />
      )}
      {seo.image && <meta name="twitter:image" content={seo.image} />}
      <link rel="canonical" href={seo.url}></link>
    </Helmet>
  );
};

export default SEO;

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  article: PropTypes.bool,
};

SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  article: false,
};

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl
        defaultImage: image
        twitterUsername
      }
    }
  }
`;

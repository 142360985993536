export const trackingLinkHelper = (url) => {
    let result = url;

    if(!url.includes('http')) {
        let hostname = window.location.hostname;
        if (hostname === 'localhost') {
            hostname = 'biotherm.dtwhk.com';
        }

        let language = window.location.pathname.split('/')[1];
        if (!url.startsWith(`/${language}`)) {
            url = `/${language}` + url;
        }

        result = hostname + url;
    }
  
    return result;
};
export const trackingHelper = (url) => {
    let result = 'content page';
    
    if(url.includes('men') || url == "/") {
        result = 'homepage';
    }
    else if(url.includes('face-care') || url.includes('body-care') ) {
        result = 'product listing page';
    }
    else if(url.includes('/product/') ) {
        result = 'product detail page';
    }
  
    return result;
  };
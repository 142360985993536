import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';

import { menuOnClick } from '../../state/reducers/common';
import TopBar from './TopBar';
import Link from '../Link';
import LogoImage from '../../assets/images/biotherm-white.png';
import { mediaQueries } from '../../utils/mediaQueries';
import SearchBar from '../SearchBar/SearchBar';

const HeaderContainer = styled.div`
  background-color: ${(props) => props.theme.headerBgColor};
  padding: 20px 15px;

  ${mediaQueries('md')} {
    padding-top: 0;
    padding-right: 0;
  }
`;

const Text = styled.p`
  color: #ffffff;
  font-size: 15px;
  margin-bottom: 0;
  text-align: center;

  span {
    display: block;
    font-size: 12px;
  }
`;

const Logo = styled(Link)`
  display: block;
  width: 150px;

  img {
    max-width: 100%;
  }
`;

const Header = () => {
  const { t,i18n } = useTranslation();
  const dispatch = useDispatch();
  const { isMenuOpen, isMobileView } = useSelector((state) => state.common);

  const handleLogoClick = () => {
    window.dataLayer.push({
      event: 'uaevent',
      event_name: 'logo_click',
      eventAction: 'select',
      eventCategory: 'main menu navigation',
      eventLabel: 'logo',
      site_language: i18n.language,
      page_category: 'homepage',
      brand: 'BIO',
      country: 'hk',
   });  
  };

  return (
    <header className={'container-width'}>
      <TopBar />
      <HeaderContainer
        className={`row no-gutters justify-content-between align-items-center`}
      >
        <Logo to="/" onClick={() => {handleLogoClick()}}>
          <img src={LogoImage} alt="Logo" />
        </Logo>
        {isMobileView ? (
          <div
            className="hamburger"
            onClick={() => dispatch(menuOnClick(!isMenuOpen))}
          >
            <div className="hamburger-box">
              <div className="hamburger-inner"></div>
            </div>
          </div>
        ) : (
          <>
            <Text>
              ALL BIOTHERM<span>Discover the Biotherm world</span>
            </Text>

            <SearchBar />
          </>
        )}
      </HeaderContainer>
    </header>
  );
};

export default Header;
